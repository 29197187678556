import type { VariantProps } from 'class-variance-authority'
import { cva } from 'class-variance-authority'
import { cn } from '#utils/utils.js'

const spacerStyles = cva('flex', {
  variants: {
    size: {
      xs: 'mb-2',
      sm: 'mb-4',
      md: 'mb-8',
      lg: 'mb-12',
      xl: 'mb-16',
      '2xl': 'mb-24',
      '3xl': 'mb-36',
      '4xl': 'mb-48'
    }
  },
  defaultVariants: {
    size: 'lg'
  }
})

type SpacerStyles = VariantProps<typeof spacerStyles>

export const Spacer = ({
  size,
  ...props
}: SpacerStyles & JSX.IntrinsicElements['div']) => (
  <div {...props} className={cn(spacerStyles({ size }), props.className)} />
)
